
/*.navbar-collapse {
	display: flex !important;
	visibility: visible !important;
  }
  .navbar-dark .navbar-toggler {
    display: block !important;
    visibility: visible !important;
}*/
/* Sikrer at Navbar.Toggle alltid er synlig og fungerer riktig */
.navbar-toggler {
    z-index: 1050;
    border: none; /* Fjern eventuell synlig ramme */
}

/* Standard hamburgerikon – bruker Bootstrap sine egne variabler */
.navbar-toggler-icon {
    transition: transform 0.3s ease;
}

/* Animasjon ved åpning og lukking */
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    transform: rotate(90deg);
}
