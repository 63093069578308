.carousel-container {
    // Default styles
    margin-left: 250px; // Space for the sidebar
    width: calc(100% - 250px);
    height: 50vh;
  
    @media (max-width: 768px) {
      // Styles for smaller screens
      margin-left: 0; // Remove left margin
      width: 100%; // Full width
      height: auto; // Adjust height
    }
  }
  
  .carousel-inner img {
    object-fit: cover;
    height: 100%; // Ensure images cover the carousel area
  }
  