.inputContainer {
  margin: 0.5em;
}

.input {
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding-right: 0.5em;
  padding-left: 0.5em;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;

  &:focus-within {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
}

.noResultTxt {
  padding-left: 0.5em;
}

.inputElement {
  width: 100%;
  height: 40px;
  border: none;

  &:focus {
    outline: none;
    border: none;
  }
}

.inputIcon {
  display: flex;
  align-items: center;
  color: #68778d;
  border: none;
}

.clearInputIcon {
  display: flex;
  align-items: center;
  color: #68778d;
  border: none;
  &:hover {
    cursor: pointer;
  }
}

.optionsList {
  padding-left: 0;
  padding-right: 0;
}

.optionsListItem {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  padding: 0.5em;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #001f3d;

  &.active {
    background-color: #f3f3f3;
    font-weight: 700;
  }

  &:active {
    font-weight: 700;
  }
  &:hover {
    background-color: #f3f3f3;
    cursor: pointer;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #dcdcdc;
  }
}

.highlight {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: black;
}

.supplierSwitcherContainer {
  height: 100%;
  min-height: auto;
  width: 400px;
  padding-bottom: 0;
}

@media all and (max-width: 991px) {

  /* CSS rules here for screens lower than 991px */
  .supplierSwitcherContainer {
      height: 100%;
      width: 100% !important;
      padding-bottom: 0;
  }
}