@import '@tradesolution/iceberg-styles/dist/css/iceberg.min.css';
@import '@tradesolution/iceberg-styles/src/scss/variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

// navbar

.navbar {
    background-color: $B700;
    color: #f7fafc;
    font-weight: 600;
}

.navbar-brand {
  color: #f7fafc;
}

.selectedIntent {
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inputgroup-button {
  padding: 5px; 
  height: 42px !important;
}

.movable-element {
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  height: 100%; /* Ensure the div has a height */

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
		transition: box-shadow 0.3s ease-in-out;
  }
}

/*Overstyrer icebergstyling for form-label og accordion header*/
.form-label{
	font-size: 14px;
	text-transform: uppercase;
}

.accordion .accordion-item .accordion-header .accordion-button{
	color: $Muted-GY400;

}